module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","short_name":"GatsbyJS","start_url":"/","background_color":"#FF0F5F","theme_color":"#FF0F5F","display":"standalone","cache_busting_mode":"none","legacy":false,"icons":[{"src":".src/components/icon/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":".src/components/icon/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":".src/components/icon/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":".src/components/icon/favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":".src/components/icon/favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"}],"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
