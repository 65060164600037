exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-auth-test-js": () => import("./../../../src/pages/authTest.js" /* webpackChunkName: "component---src-pages-auth-test-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-community-forumroute-js": () => import("./../../../src/pages/community/forumroute.js" /* webpackChunkName: "component---src-pages-community-forumroute-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community/[...].js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-community-routes-forum-js": () => import("./../../../src/pages/community/routes/forum.js" /* webpackChunkName: "component---src-pages-community-routes-forum-js" */),
  "component---src-pages-community-routes-posts-js": () => import("./../../../src/pages/community/routes/posts.js" /* webpackChunkName: "component---src-pages-community-routes-posts-js" */),
  "component---src-pages-community-routes-topics-js": () => import("./../../../src/pages/community/routes/topics.js" /* webpackChunkName: "component---src-pages-community-routes-topics-js" */),
  "component---src-pages-complete-profile-js": () => import("./../../../src/pages/complete-profile.js" /* webpackChunkName: "component---src-pages-complete-profile-js" */),
  "component---src-pages-forum-forumroute-js": () => import("./../../../src/pages/forum/forumroute.js" /* webpackChunkName: "component---src-pages-forum-forumroute-js" */),
  "component---src-pages-forum-js": () => import("./../../../src/pages/forum/[...].js" /* webpackChunkName: "component---src-pages-forum-js" */),
  "component---src-pages-forum-routes-category-js": () => import("./../../../src/pages/forum/routes/category.js" /* webpackChunkName: "component---src-pages-forum-routes-category-js" */),
  "component---src-pages-forum-routes-forum-js": () => import("./../../../src/pages/forum/routes/forum.js" /* webpackChunkName: "component---src-pages-forum-routes-forum-js" */),
  "component---src-pages-forum-routes-posts-js": () => import("./../../../src/pages/forum/routes/posts.js" /* webpackChunkName: "component---src-pages-forum-routes-posts-js" */),
  "component---src-pages-forum-routes-search-js": () => import("./../../../src/pages/forum/routes/search.js" /* webpackChunkName: "component---src-pages-forum-routes-search-js" */),
  "component---src-pages-forum-routes-topics-by-tag-name-js": () => import("./../../../src/pages/forum/routes/topicsByTagName.js" /* webpackChunkName: "component---src-pages-forum-routes-topics-by-tag-name-js" */),
  "component---src-pages-forum-routes-topics-js": () => import("./../../../src/pages/forum/routes/topics.js" /* webpackChunkName: "component---src-pages-forum-routes-topics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-loginlink-js": () => import("./../../../src/pages/loginlink.js" /* webpackChunkName: "component---src-pages-loginlink-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-wealthwizard-js": () => import("./../../../src/pages/wealthwizard.js" /* webpackChunkName: "component---src-pages-wealthwizard-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-fund-info-js": () => import("./../../../src/templates/fundInfo.js" /* webpackChunkName: "component---src-templates-fund-info-js" */),
  "component---src-templates-information-js": () => import("./../../../src/templates/information.js" /* webpackChunkName: "component---src-templates-information-js" */),
  "component---src-templates-landingpages-js": () => import("./../../../src/templates/landingpages.js" /* webpackChunkName: "component---src-templates-landingpages-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-question-post-js": () => import("./../../../src/templates/question-post.js" /* webpackChunkName: "component---src-templates-question-post-js" */),
  "component---src-templates-visible-people-js": () => import("./../../../src/templates/visiblePeople.js" /* webpackChunkName: "component---src-templates-visible-people-js" */)
}

